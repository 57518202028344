export const countries = {
  af: {
    name: 'Afghanistan',
    code: 'af',
    phoneCode: '93',
    region: 4,
  },
  al: {
    name: 'Albania',
    code: 'al',
    phoneCode: '355',
    region: 2,
  },
  dz: {
    name: 'Algeria',
    code: 'dz',
    phoneCode: '213',
    region: 4,
  },
  ad: {
    name: 'Andorra',
    code: 'ad',
    phoneCode: '376',
    region: 2,
  },
  ao: {
    name: 'Angola',
    code: 'ao',
    phoneCode: '244',
    region: 4,
  },
  ag: {
    name: 'Antigua and Barbuda',
    code: 'ag',
    phoneCode: '1268',
    region: 1,
  },
  ar: {
    name: 'Argentina',
    code: 'ar',
    phoneCode: '54',
    region: 1,
  },
  am: {
    name: 'Armenia',
    code: 'am',
    phoneCode: '374',
    region: 4,
  },
  aw: {
    name: 'Aruba',
    code: 'aw',
    phoneCode: '297',
    region: 1,
  },
  au: {
    name: 'Australia',
    code: 'au',
    phoneCode: '61',
    region: 3,
  },
  at: {
    name: 'Austria',
    code: 'at',
    phoneCode: '43',
    region: 2,
  },
  az: {
    name: 'Azerbaijan',
    code: 'az',
    phoneCode: '994',
    region: 4,
  },
  bs: {
    name: 'Bahamas',
    code: 'bs',
    phoneCode: '1242',
    region: 1,
  },
  bh: {
    name: 'Bahrain',
    code: 'bh',
    phoneCode: '973',
    region: 4,
  },
  bd: {
    name: 'Bangladesh',
    code: 'bd',
    phoneCode: '880',
    region: 4,
  },
  bb: {
    name: 'Barbados',
    code: 'bb',
    phoneCode: '1246',
    region: 1,
  },
  by: {
    name: 'Belarus',
    code: 'by',
    phoneCode: '375',
    region: 5,
  },
  be: {
    name: 'Belgium',
    code: 'be',
    phoneCode: '32',
    region: 2,
  },
  bz: {
    name: 'Belize',
    code: 'bz',
    phoneCode: '501',
    region: 1,
  },
  bj: {
    name: 'Benin',
    code: 'bj',
    phoneCode: '229',
    region: 4,
  },
  bt: {
    name: 'Bhutan',
    code: 'bt',
    phoneCode: '975',
    region: 4,
  },
  bo: {
    name: 'Bolivia',
    code: 'bo',
    phoneCode: '591',
    region: 1,
  },
  ba: {
    name: 'Bosnia & Herzegovina',
    code: 'ba',
    phoneCode: '387',
    region: 2,
  },
  bw: {
    name: 'Botswana',
    code: 'bw',
    phoneCode: '267',
    region: 4,
  },
  br: {
    name: 'Brazil',
    code: 'br',
    phoneCode: '55',
    region: 1,
  },
  io: {
    name: 'British Indian OT',
    code: 'io',
    phoneCode: '246',
    region: 4,
  },
  bn: {
    name: 'Brunei',
    code: 'bn',
    phoneCode: '673',
    region: 4,
  },
  bg: {
    name: 'Bulgaria',
    code: 'bg',
    phoneCode: '359',
    region: 2,
  },
  bf: {
    name: 'Burkina Faso',
    code: 'bf',
    phoneCode: '226',
    region: 4,
  },
  bi: {
    name: 'Burundi',
    code: 'bi',
    phoneCode: '257',
    region: 4,
  },
  kh: {
    name: 'Cambodia',
    code: 'kh',
    phoneCode: '855',
    region: 4,
  },
  cm: {
    name: 'Cameroon',
    code: 'cm',
    phoneCode: '237',
    region: 4,
  },
  ca: {
    name: 'Canada',
    code: 'ca',
    phoneCode: '1',
    region: 1,
  },
  cv: {
    name: 'Cape Verde',
    code: 'cv',
    phoneCode: '238',
    region: 4,
  },
  bq: {
    name: 'Caribbean Netherlands',
    code: 'bq',
    phoneCode: '599',
    region: 1,
  },
  cf: {
    name: 'CAR',
    code: 'cf',
    phoneCode: '236',
    region: 4,
  },
  td: {
    name: 'Chad',
    code: 'td',
    phoneCode: '235',
    region: 4,
  },
  cl: {
    name: 'Chile',
    code: 'cl',
    phoneCode: '56',
    region: 1,
  },
  cn: {
    name: 'China',
    code: 'cn',
    phoneCode: '86',
    region: 4,
  },
  co: {
    name: 'Colombia',
    code: 'co',
    phoneCode: '57',
    region: 1,
  },
  km: {
    name: 'Comoros',
    code: 'km',
    phoneCode: '269',
    region: 4,
  },
  cd: {
    name: 'Congo',
    code: 'cd',
    phoneCode: '243',
    region: 4,
  },
  cg: {
    name: 'Congo',
    code: 'cg',
    phoneCode: '242',
    region: 4,
  },
  cr: {
    name: 'Costa Rica',
    code: 'cr',
    phoneCode: '506',
    region: 1,
  },
  ci: {
    name: 'Côte d’Ivoire',
    code: 'ci',
    phoneCode: '225',
    region: 4,
  },
  hr: {
    name: 'Croatia',
    code: 'hr',
    phoneCode: '385',
    region: 2,
  },
  cu: {
    name: 'Cuba',
    code: 'cu',
    phoneCode: '53',
    region: 5,
  },
  cw: {
    name: 'Curaçao',
    code: 'cw',
    phoneCode: '599',
    region: 1,
  },
  cy: {
    name: 'Cyprus',
    code: 'cy',
    phoneCode: '357',
    region: 2,
  },
  cz: {
    name: 'Czech Republic',
    code: 'cz',
    phoneCode: '420',
    region: 2,
  },
  dk: {
    name: 'Denmark',
    code: 'dk',
    phoneCode: '45',
    region: 2,
  },
  dj: {
    name: 'Djibouti',
    code: 'dj',
    phoneCode: '253',
    region: 4,
  },
  dm: {
    name: 'Dominica',
    code: 'dm',
    phoneCode: '1767',
    region: 1,
  },
  do: {
    name: 'Dominican Republic',
    code: 'do',
    phoneCode: '1',
    region: 1,
  },
  ec: {
    name: 'Ecuador',
    code: 'ec',
    phoneCode: '593',
    region: 1,
  },
  eg: {
    name: 'Egypt',
    code: 'eg',
    phoneCode: '20',
    region: 4,
  },
  sv: {
    name: 'El Salvador',
    code: 'sv',
    phoneCode: '503',
    region: 1,
  },
  gq: {
    name: 'Equatorial Guinea',
    code: 'gq',
    phoneCode: '240',
    region: 4,
  },
  er: {
    name: 'Eritrea',
    code: 'er',
    phoneCode: '291',
    region: 4,
  },
  ee: {
    name: 'Estonia',
    code: 'ee',
    phoneCode: '372',
    region: 2,
  },
  et: {
    name: 'Ethiopia',
    code: 'et',
    phoneCode: '251',
    region: 4,
  },
  fj: {
    name: 'Fiji',
    code: 'fj',
    phoneCode: '679',
    region: 3,
  },
  fi: {
    name: 'Finland',
    code: 'fi',
    phoneCode: '358',
    region: 2,
  },
  fr: {
    name: 'France',
    code: 'fr',
    phoneCode: '33',
    region: 2,
  },
  gf: {
    name: 'French Guiana',
    code: 'gf',
    phoneCode: '594',
    region: 1,
  },
  pf: {
    name: 'French Polynesia',
    code: 'pf',
    phoneCode: '689',
    region: 4,
  },
  ga: {
    name: 'Gabon',
    code: 'ga',
    phoneCode: '241',
    region: 4,
  },
  gm: {
    name: 'Gambia',
    code: 'gm',
    phoneCode: '220',
    region: 4,
  },
  ge: {
    name: 'Georgia',
    code: 'ge',
    phoneCode: '995',
    region: 4,
  },
  de: {
    name: 'Germany',
    code: 'de',
    phoneCode: '49',
    region: 2,
  },
  gh: {
    name: 'Ghana',
    code: 'gh',
    phoneCode: '233',
    region: 4,
  },
  gr: {
    name: 'Greece',
    code: 'gr',
    phoneCode: '30',
    region: 2,
  },
  gd: {
    name: 'Grenada',
    code: 'gd',
    phoneCode: '1473',
    region: 1,
  },
  gp: {
    name: 'Guadeloupe',
    code: 'gp',
    phoneCode: '590',
    region: 1,
  },
  gu: {
    name: 'Guam',
    code: 'gu',
    phoneCode: '1671',
    region: 4,
  },
  gt: {
    name: 'Guatemala',
    code: 'gt',
    phoneCode: '502',
    region: 1,
  },
  gn: {
    name: 'Guinea',
    code: 'gn',
    phoneCode: '224',
    region: 4,
  },
  gw: {
    name: 'Guinea-Bissau',
    code: 'gw',
    phoneCode: '245',
    region: 4,
  },
  gy: {
    name: 'Guyana',
    code: 'gy',
    phoneCode: '592',
    region: 1,
  },
  ht: {
    name: 'Haiti',
    code: 'ht',
    phoneCode: '509',
    region: 1,
  },
  hn: {
    name: 'Honduras',
    code: 'hn',
    phoneCode: '504',
    region: 1,
  },
  hk: {
    name: 'Hong Kong',
    code: 'hk',
    phoneCode: '852',
    region: 2,
  },
  hu: {
    name: 'Hungary',
    code: 'hu',
    phoneCode: '36',
    region: 2,
  },
  is: {
    name: 'Iceland',
    code: 'is',
    phoneCode: '354',
    region: 2,
  },
  in: {
    name: 'India',
    code: 'in',
    phoneCode: '91',
    region: 4,
  },
  id: {
    name: 'Indonesia',
    code: 'id',
    phoneCode: '62',
    region: 4,
  },
  ir: {
    name: 'Iran',
    code: 'ir',
    phoneCode: '98',
    region: 5,
  },
  iq: {
    name: 'Iraq',
    code: 'iq',
    phoneCode: '964',
    region: 4,
  },
  ie: {
    name: 'Ireland',
    code: 'ie',
    phoneCode: '353',
    region: 2,
  },
  il: {
    name: 'Israel',
    code: 'IL',
    phoneCode: '972',
    region: 2,
  },
  it: {
    name: 'Italy',
    code: 'it',
    phoneCode: '39',
    region: 2,
  },
  jm: {
    name: 'Jamaica',
    code: 'jm',
    phoneCode: '1876',
    region: 1,
  },
  jp: {
    name: 'Japan',
    code: 'jp',
    phoneCode: '81',
    region: 4,
  },
  jo: {
    name: 'Jordan',
    code: 'jo',
    phoneCode: '962',
    region: 4,
  },
  kz: {
    name: 'Kazakhstan',
    code: 'kz',
    phoneCode: '7',
    region: 4,
  },
  ke: {
    name: 'Kenya',
    code: 'ke',
    phoneCode: '254',
    region: 4,
  },
  ki: {
    name: 'Kiribati',
    code: 'ki',
    phoneCode: '686',
    region: 3,
  },
  xk: {
    name: 'Kosovo',
    code: 'xk',
    phoneCode: '383',
    region: 2,
  },
  kw: {
    name: 'Kuwait',
    code: 'kw',
    phoneCode: '965',
    region: 4,
  },
  kg: {
    name: 'Kyrgyzstan',
    code: 'kg',
    phoneCode: '996',
    region: 4,
  },
  la: {
    name: 'Laos',
    code: 'la',
    phoneCode: '856',
    region: 4,
  },
  lv: {
    name: 'Latvia',
    code: 'lv',
    phoneCode: '371',
    region: 2,
  },
  lb: {
    name: 'Lebanon',
    code: 'lb',
    phoneCode: '961',
    region: 4,
  },
  ls: {
    name: 'Lesotho',
    code: 'ls',
    phoneCode: '266',
    region: 4,
  },
  lr: {
    name: 'Liberia',
    code: 'lr',
    phoneCode: '231',
    region: 4,
  },
  ly: {
    name: 'Libya',
    code: 'ly',
    phoneCode: '218',
    region: 4,
  },
  li: {
    name: 'Liechtenstein',
    code: 'li',
    phoneCode: '423',
    region: 2,
  },
  lt: {
    name: 'Lithuania',
    code: 'lt',
    phoneCode: '370',
    region: 2,
  },
  lu: {
    name: 'Luxembourg',
    code: 'lu',
    phoneCode: '352',
    region: 2,
  },
  mo: {
    name: 'Macau',
    code: 'mo',
    phoneCode: '853',
    region: 4,
  },
  mk: {
    name: 'Macedonia',
    code: 'mk',
    phoneCode: '389',
    region: 2,
  },
  mg: {
    name: 'Madagascar',
    code: 'mg',
    phoneCode: '261',
    region: 4,
  },
  mw: {
    name: 'Malawi',
    code: 'mw',
    phoneCode: '265',
    region: 4,
  },
  my: {
    name: 'Malaysia',
    code: 'my',
    phoneCode: '60',
    region: 4,
  },
  mv: {
    name: 'Maldives',
    code: 'mv',
    phoneCode: '960',
    region: 4,
  },
  ml: {
    name: 'Mali',
    code: 'ml',
    phoneCode: '223',
    region: 4,
  },
  mt: {
    name: 'Malta',
    code: 'mt',
    phoneCode: '356',
    region: 2,
  },
  mh: {
    name: 'Marshall Islands',
    code: 'mh',
    phoneCode: '692',
    region: 3,
  },
  mq: {
    name: 'Martinique',
    code: 'mq',
    phoneCode: '596',
    region: 1,
  },
  mr: {
    name: 'Mauritania',
    code: 'mr',
    phoneCode: '222',
    region: 4,
  },
  mu: {
    name: 'Mauritius',
    code: 'mu',
    phoneCode: '230',
    region: 4,
  },
  mx: {
    name: 'Mexico',
    code: 'mx',
    phoneCode: '52',
    region: 1,
  },
  fm: {
    name: 'Micronesia',
    code: 'fm',
    phoneCode: '691',
    region: 3,
  },
  md: {
    name: 'Moldova',
    code: 'md',
    phoneCode: '373',
    region: 2,
  },
  mc: {
    name: 'Monaco',
    code: 'mc',
    phoneCode: '377',
    region: 2,
  },
  mn: {
    name: 'Mongolia',
    code: 'mn',
    phoneCode: '976',
    region: 4,
  },
  me: {
    name: 'Montenegro',
    code: 'me',
    phoneCode: '382',
    region: 2,
  },
  ma: {
    name: 'Morocco',
    code: 'ma',
    phoneCode: '212',
    region: 4,
  },
  mz: {
    name: 'Mozambique',
    code: 'mz',
    phoneCode: '258',
    region: 4,
  },
  mm: {
    name: 'Myanmar',
    code: 'mm',
    phoneCode: '95',
    region: 4,
  },
  na: {
    name: 'Namibia',
    code: 'na',
    phoneCode: '264',
    region: 4,
  },
  nr: {
    name: 'Nauru',
    code: 'nr',
    phoneCode: '674',
    region: 3,
  },
  np: {
    name: 'Nepal',
    code: 'np',
    phoneCode: '977',
    region: 4,
  },
  nl: {
    name: 'Netherlands',
    code: 'nl',
    phoneCode: '31',
    region: 2,
  },
  nc: {
    name: 'New Caledonia',
    code: 'nc',
    phoneCode: '687',
    region: 3,
  },
  nz: {
    name: 'New Zealand',
    code: 'nz',
    phoneCode: '64',
    region: 3,
  },
  ni: {
    name: 'Nicaragua',
    code: 'ni',
    phoneCode: '505',
    region: 1,
  },
  ne: {
    name: 'Niger',
    code: 'ne',
    phoneCode: '227',
    region: 4,
  },
  ng: {
    name: 'Nigeria',
    code: 'ng',
    phoneCode: '234',
    region: 4,
  },
  kp: {
    name: 'North Korea',
    code: 'kp',
    phoneCode: '850',
    region: 5,
  },
  no: {
    name: 'Norway',
    code: 'no',
    phoneCode: '47',
    region: 2,
  },
  om: {
    name: 'Oman',
    code: 'om',
    phoneCode: '968',
    region: 4,
  },
  pk: {
    name: 'Pakistan',
    code: 'pk',
    phoneCode: '92',
    region: 4,
  },
  pw: {
    name: 'Palau',
    code: 'pw',
    phoneCode: '680',
    region: 3,
  },
  ps: {
    name: 'Palestine',
    code: 'ps',
    phoneCode: '970',
    region: 4,
  },
  pa: {
    name: 'Panama',
    code: 'pa',
    phoneCode: '507',
    region: 1,
  },
  pg: {
    name: 'Papua New Guinea',
    code: 'pg',
    phoneCode: '675',
    region: 3,
  },
  py: {
    name: 'Paraguay',
    code: 'py',
    phoneCode: '595',
    region: 1,
  },
  pe: {
    name: 'Peru',
    code: 'pe',
    phoneCode: '51',
    region: 1,
  },
  ph: {
    name: 'Philippines',
    code: 'ph',
    phoneCode: '63',
    region: 4,
  },
  pl: {
    name: 'Poland',
    code: 'pl',
    phoneCode: '48',
    region: 2,
  },
  pt: {
    name: 'Portugal',
    code: 'pt',
    phoneCode: '351',
    region: 2,
  },
  pr: {
    name: 'Puerto Rico',
    code: 'pr',
    phoneCode: '1',
    region: 1,
  },
  qa: {
    name: 'Qatar',
    code: 'qa',
    phoneCode: '974',
    region: 4,
  },
  re: {
    name: 'Réunion',
    code: 're',
    phoneCode: '262',
    region: 4,
  },
  ro: {
    name: 'Romania',
    code: 'ro',
    phoneCode: '40',
    region: 2,
  },
  ru: {
    name: 'Russia',
    code: 'ru',
    phoneCode: '7',
    region: 5,
  },
  rw: {
    name: 'Rwanda',
    code: 'rw',
    phoneCode: '250',
    region: 4,
  },
  kn: {
    name: 'Saint Kitts and Nevis',
    code: 'kn',
    phoneCode: '1869',
    region: 1,
  },
  lc: {
    name: 'Saint Lucia',
    code: 'lc',
    phoneCode: '1758',
    region: 1,
  },
  vc: {
    name: 'St. Vincent & the Grenadines',
    code: 'vc',
    phoneCode: '1784',
    region: 1,
  },
  ws: {
    name: 'Samoa',
    code: 'ws',
    phoneCode: '685',
    region: 3,
  },
  sm: {
    name: 'San Marino',
    code: 'sm',
    phoneCode: '378',
    region: 2,
  },
  st: {
    name: 'São Tomé & Príncipe',
    code: 'st',
    phoneCode: '239',
    region: 4,
  },
  sa: {
    name: 'Saudi Arabia',
    code: 'sa',
    phoneCode: '966',
    region: 4,
  },
  sn: {
    name: 'Senegal',
    code: 'sn',
    phoneCode: '221',
    region: 4,
  },
  rs: {
    name: 'Serbia',
    code: 'rs',
    phoneCode: '381',
    region: 2,
  },
  sc: {
    name: 'Seychelles',
    code: 'sc',
    phoneCode: '248',
    region: 4,
  },
  sl: {
    name: 'Sierra Leone',
    code: 'sl',
    phoneCode: '232',
    region: 4,
  },
  sg: {
    name: 'Singapore',
    code: 'sg',
    phoneCode: '65',
    region: 4,
  },
  sk: {
    name: 'Slovakia',
    code: 'sk',
    phoneCode: '421',
    region: 2,
  },
  si: {
    name: 'Slovenia',
    code: 'si',
    phoneCode: '386',
    region: 2,
  },
  sb: {
    name: 'Solomon Islands',
    code: 'sb',
    phoneCode: '677',
    region: 3,
  },
  so: {
    name: 'Somalia',
    code: 'so',
    phoneCode: '252',
    region: 4,
  },
  za: {
    name: 'South Africa',
    code: 'za',
    phoneCode: '27',
    region: 4,
  },
  kr: {
    name: 'South Korea',
    code: 'kr',
    phoneCode: '82',
    region: 4,
  },
  ss: {
    name: 'South Sudan',
    code: 'ss',
    phoneCode: '211',
    region: 5,
  },
  es: {
    name: 'Spain',
    code: 'es',
    phoneCode: '34',
    region: 2,
  },
  lk: {
    name: 'Sri Lanka',
    code: 'lk',
    phoneCode: '94',
    region: 4,
  },
  sd: {
    name: 'Sudan',
    code: 'sd',
    phoneCode: '249',
    region: 5,
  },
  sr: {
    name: 'Suriname',
    code: 'sr',
    phoneCode: '597',
    region: 1,
  },
  sz: {
    name: 'Swaziland',
    code: 'sz',
    phoneCode: '268',
    region: 4,
  },
  se: {
    name: 'Sweden',
    code: 'se',
    phoneCode: '46',
    region: 2,
  },
  ch: {
    name: 'Switzerland',
    code: 'ch',
    phoneCode: '41',
    region: 2,
  },
  sy: {
    name: 'Syria',
    code: 'sy',
    phoneCode: '963',
    region: 5,
  },
  tw: {
    name: 'Taiwan',
    code: 'tw',
    phoneCode: '886',
    region: 4,
  },
  tj: {
    name: 'Tajikistan',
    code: 'tj',
    phoneCode: '992',
    region: 4,
  },
  tz: {
    name: 'Tanzania',
    code: 'tz',
    phoneCode: '255',
    region: 4,
  },
  th: {
    name: 'Thailand',
    code: 'th',
    phoneCode: '66',
    region: 4,
  },
  tl: {
    name: 'Timor-Leste',
    code: 'tl',
    phoneCode: '670',
    region: 3,
  },
  tg: {
    name: 'Togo',
    code: 'tg',
    phoneCode: '228',
    region: 4,
  },
  to: {
    name: 'Tonga',
    code: 'to',
    phoneCode: '676',
    region: 3,
  },
  tt: {
    name: 'Trinidad and Tobago',
    code: 'tt',
    phoneCode: '1868',
    region: 1,
  },
  tn: {
    name: 'Tunisia',
    code: 'tn',
    phoneCode: '216',
    region: 4,
  },
  tr: {
    name: 'Turkey',
    code: 'tr',
    phoneCode: '90',
    region: 4,
  },
  tm: {
    name: 'Turkmenistan',
    code: 'tm',
    phoneCode: '993',
    region: 4,
  },
  tv: {
    name: 'Tuvalu',
    code: 'tv',
    phoneCode: '688',
    region: 3,
  },
  ug: {
    name: 'Uganda',
    code: 'ug',
    phoneCode: '256',
    region: 4,
  },
  ua: {
    name: 'Ukraine',
    code: 'ua',
    phoneCode: '380',
    region: 2,
  },
  ae: {
    name: 'UAE',
    code: 'ae',
    phoneCode: '971',
    region: 4,
  },
  gb: {
    name: 'United Kingdom',
    code: 'gb',
    phoneCode: '44',
    region: 2,
  },
  us: {
    name: 'United States',
    code: 'us',
    phoneCode: '1',
    region: 1,
  },
  uy: {
    name: 'Uruguay',
    code: 'uy',
    phoneCode: '598',
    region: 1,
  },
  uz: {
    name: 'Uzbekistan',
    code: 'uz',
    phoneCode: '998',
    region: 4,
  },
  vu: {
    name: 'Vanuatu',
    code: 'vu',
    phoneCode: '678',
    region: 3,
  },
  va: {
    name: 'Vatican City',
    code: 'va',
    phoneCode: '39',
    region: 2,
  },
  ve: {
    name: 'Venezuela',
    code: 've',
    phoneCode: '58',
    region: 1,
  },
  vn: {
    name: 'Vietnam',
    code: 'vn',
    phoneCode: '84',
    region: 4,
  },
  ye: {
    name: 'Yemen',
    code: 'ye',
    phoneCode: '967',
    region: 4,
  },
  zm: {
    name: 'Zambia',
    code: 'zm',
    phoneCode: '260',
    region: 4,
  },
  zw: {
    name: 'Zimbabwe',
    code: 'zw',
    phoneCode: '263',
    region: 4,
  },
};

// Forms according to company size.
export const calendlyForms = {
  1: {
    small: '2tx-qz6-zzr/',
    medium: 'yt8-p93-27r/',
    large: '2v3-xnf-rgk/',
    xlarge: 'y7v-vrp-5v6/',
  },
  2: {
    small: '2t2-242-4ph/',
    medium: 'y4s-sy6-rz7/',
    large: '4ch-ykn-ptq/',
    xlarge: 'y4s-gcp-547/',
  },
  3: {
    small: '2yg-h2y-cwg/',
    medium: 'yhk-m2k-jy5/',
    large: '4sd-cst-5hc/',
    xlarge: 'zky-5hg-hjv/',
  },
};
