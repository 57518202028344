<script setup lang="ts">
import {
  onBeforeMount,
  ref,
  computed,
  onUnmounted,
  onMounted,
  watch,
} from 'vue';
import { deferLoading, loadingDone, navigateTo } from '@mfl/framework';
import { auth4Sdks, WEBAPP_BASE_URL } from '@mfl/platform-shell';
import {
  toast,
  ToastStatus,
  WsButton,
  WsTooltip,
} from '@mfl/common-components';
import {
  AccountType,
  EmailPlatform,
  onboardingGateway,
  OnboardingStatus,
  ResponseStatusCode,
} from '@msl/onboarding-gateway-sdk';
import strings from './onboarding.strings';
import logoSrc from './assets/imgs/logo.svg';
import { OnboardingData } from './types';
import onboardingStrings from './onboarding.strings';
import { bookDemoSession, screens } from './consts';
import { showOverlay, setCalendlyUrl, sendAnalytics } from './form-actions';

const onboardingData = ref<OnboardingData>({
  userBasicInfo: {},
  accountTypeInfo: {},
  workspaceInfo: {},
  demoSession: undefined,
  demoScheduled: false,
  userEmail: '',
  userCountry: '',
  calendlyUrl: '',
});

const onboardingMargin = ref<HTMLBodyElement>();
const inProgress = ref<boolean>(false);
const hideMargin = ref<boolean>(false);
const currentStep = ref(-1);
const showOverlayMsg = ref<string>('');

onBeforeMount(async () => {
  try {
    deferLoading();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ws_user_hash = urlParams.get('ws_user_hash');
    cookieStore.set('ws_user_hash', ws_user_hash);

    await onboardingGateway.init(auth4Sdks());
    await initOnboardingData();
  } catch (e) {
    console.error('get onboarding status failed', e);
    errorNotification();
  } finally {
    loadingDone();
  }
});

onMounted(() => {
  window.addEventListener('resize', onResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});

const currentScreen = computed(() => {
  return screens[currentStep.value];
});

const isLastScreen = computed(() => {
  return currentStep.value == screens.length - 1;
});

const isFirstScreen = computed(() => {
  return currentStep.value == 0;
});

const isRequiredDataFilled = computed(() => {
  switch (currentStep.value) {
    case 0:
      return (
        !!onboardingData.value.userBasicInfo.userName &&
        !!onboardingData.value.userBasicInfo.userMobile &&
        !!onboardingData.value.userBasicInfo.companySize &&
        !!onboardingData.value.userBasicInfo.companyName
      );
    case 1:
      return (
        onboardingData.value.accountTypeInfo.accountType !== undefined &&
        onboardingData.value.accountTypeInfo.accountType in AccountType
      );
    case 2:
      return (
        (!onboardingData.value.calendlyUrl ||
          (onboardingData.value.demoSession !== undefined &&
            onboardingData.value.demoSession in bookDemoSession)) &&
        onboardingData.value.workspaceInfo.emailPlatform !== undefined &&
        onboardingData.value.workspaceInfo.emailPlatform in EmailPlatform
      );
    default:
      return false;
  }
});

const isContinueBtnDisabled = computed(() => {
  return !isRequiredDataFilled.value;
});

watch(onboardingMargin, async () => {
  onResize();
});

watch(currentStep, async (newVal) => {
  let eventName = '';
  if (newVal == 0) eventName = 'onboarding_sql_step_view';
  if (newVal == 1) eventName = 'onboarding_use_case_view';
  if (newVal == 2) eventName = 'onboarding_email_provider_view';
  if (eventName) {
    await sendAnalytics({ eventName });
  }
});

function onResize() {
  if (!onboardingMargin.value) return;
  if (onboardingMargin.value?.offsetWidth <= 500) {
    hideMargin.value = true;
  } else hideMargin.value = false;
}

function errorNotification() {
  toast({
    status: ToastStatus.Error,
    message: onboardingStrings.generalServerError,
    aid: 'ONBOARDING_ERROR_TOAST',
  });
}

function completeOnboarding() {
  setTimeout(() => {
    showOverlayMsg.value =
      onboardingStrings.completeOnboardingTakingToWorkspace;
    setTimeout(() => {
      sessionStorage.removeItem('domainId');
      sessionStorage.removeItem('domainKey');
      navigateTo(`${WEBAPP_BASE_URL}/`, false, true);
    }, 1000);
  }, 2500);
}

function initCurrentStep(onboardingStatus: OnboardingStatus): void {
  if (!onboardingStatus?.sqlPage) {
    currentStep.value = 0;
  } else if (!onboardingStatus?.setupPage) {
    currentStep.value = 1;
  } else if (!onboardingStatus?.setWorkspace) {
    currentStep.value = 2;
  }
}

async function initOnboardingData(): Promise<void> {
  const resp = await onboardingGateway.getBasicInfo({});
  initCurrentStep(resp.onboardingStatus || {});
  onboardingData.value.userBasicInfo = {
    companyName: resp.companyName,
    companySize: resp.companySize,
    userMobile: resp.userMobile,
    userName: resp.userName,
    userTitle: resp.userTitle,
  };
  onboardingData.value.workspaceInfo.emailPlatform = resp.emailPlatform;
  onboardingData.value.accountTypeInfo.accountType = resp.accountType;
  onboardingData.value.accountTypeInfo.companyIndustry = resp.companyIndustry;
  onboardingData.value.userEmail = resp.userEmail || '';
  onboardingData.value.userCountry = resp.userCountry || '';
  setCalendlyUrl(onboardingData.value);
}

function nextStep() {
  if (isLastScreen.value) {
    return;
  }

  currentStep.value++;
}

function prevStep() {
  if (isFirstScreen.value) {
    return;
  }

  currentStep.value--;
}

async function formSubmit() {
  inProgress.value = true;
  showOverlayMsg.value = showOverlay(
    onboardingData.value,
    currentScreen.value.aid
  )
    ? onboardingStrings.completeOnboardingGettingReadyMsg
    : '';
  const resp = await currentScreen.value.action(onboardingData.value);
  await sendAnalytics(resp);
  if (showOverlayMsg.value && resp.statusCode == ResponseStatusCode.NO_ERRORS) {
    await completeOnboarding();
  } else {
    inProgress.value = false;
    showOverlayMsg.value = '';
    if (resp.statusCode == ResponseStatusCode.NO_ERRORS) {
      setCalendlyUrl(onboardingData.value);
      nextStep();
    } else {
      errorNotification();
    }
  }
}
</script>

<template>
  <div v-if="currentScreen" class="onboarding-container">
    <div :class="['form', { 'form-no-margin': hideMargin }]">
      <div class="form-content" :aid="currentScreen.aid">
        <img :src="logoSrc" />
        <component
          :is="currentScreen.form"
          v-model="onboardingData"
        ></component>
      </div>
      <div :class="['footer', { 'justify-content-flex-end': isFirstScreen }]">
        <WsButton
          v-if="!isFirstScreen"
          color="gray-500"
          :label="strings.buttonBackLabel"
          size="lg"
          variant="text"
          icon="fa-regular fa-chevron-left"
          :aid="`SIGNUP_FLOW_CONTINUE_BTN_${currentScreen.aid}`"
          @click="prevStep"
        />
        <WsButton
          color="primary"
          :label="strings.buttonContinueLabel"
          :disabled="isContinueBtnDisabled"
          :loading="inProgress"
          size="lg"
          :aid="`SIGNUP_FLOW_CONTINUE_BTN_${currentScreen.aid}`"
          @click="formSubmit"
        >
          {{ strings.buttonContinueLabel }}
          <WsTooltip
            v-if="isContinueBtnDisabled"
            aid="SIGNUP_FLOW_CONTINUE_BTN_TOOLTIP"
          >
            {{ strings.buttonContinueDisabledTooltip }}
          </WsTooltip>
        </WsButton>
      </div>
    </div>
    <div ref="onboardingMargin" class="margin">
      <component :is="currentScreen.svg" v-show="!hideMargin"></component>
    </div>
  </div>
  <div v-if="showOverlayMsg" class="overlay">
    <div class="message">
      <div class="text-sm font-semibold">{{ showOverlayMsg }}</div>
      <div class="loader"></div>
    </div>
  </div>
</template>

<style lang="css" scoped src="./assets/css/onboarding-mfe.css"></style>
