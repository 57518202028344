<script setup lang="ts">
import { RadioButtonOption } from '../types';

type Props = {
  options?: RadioButtonOption[];
  aid: string;
  optionWidth?: string;
  disabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  disabled: false,
});

const model = defineModel<string | number | boolean>();

function onClick(option: RadioButtonOption) {
  if (!props.disabled) {
    model.value = option.value;
  }
}
</script>
<template>
  <div :aid="aid" class="chips-group">
    <div
      v-for="(option, idx) in options"
      :key="`${idx}${aid}`"
      :class="[
        'text-md',
        'font-normal',
        'chip',
        { selected: model == option.value },
        { disabled: disabled },
      ]"
      :style="{ width: optionWidth }"
      :aid="`${aid}_${idx}`"
      @click="onClick(option)"
    >
      <span :class="option.className">{{ option.label }}</span>
    </div>
  </div>
</template>
<style>
.chips-group {
  display: flex;
  align-items: center;
  gap: 14px;
  flex-wrap: wrap;
}

.chip {
  padding: 4px 24px;
  height: 48px;
  border-radius: 50px;
  border: 1px solid #e0e0e0;
  text-align: center;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chip:hover {
  cursor: pointer;
}

.selected {
  border: 1px solid #1e90fa;
}
</style>
