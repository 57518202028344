import {
  AccountType,
  onboardingGateway,
  ResponseStatusCode,
} from '@msl/onboarding-gateway-sdk';
// import { trackEvent, cioTrack } from '@mfl/platform-shell';
import { CompletedStepResponse, OnboardingData } from './types';
import { screenName } from './consts';
import { calendlyForms, countries } from './components/calendly/consts';
import { currentUser } from '@mfl/platform-shell';

/**
 * submit form of the first onboarding step
 *
 * @returns gateway response
 */
export const tellUsAboutYouFormSubmit = async (
  data: OnboardingData
): Promise<CompletedStepResponse> => {
  const res = await onboardingGateway.updateBasicInfo(data.userBasicInfo);
  const userFullName = data.userBasicInfo.userName?.split(' ');
  return {
    statusCode: res.statusCode,
    eventName: 'onboarding_sql_step_completed',
    cioEventName: 'company_data_update',
    cioEventAttributes: {
      company_name: data.userBasicInfo.companyName || '',
      company_size: data.userBasicInfo.companySize || '',
      title: data.userBasicInfo.userTitle || '',
      full_name: data.userBasicInfo.userName || '',
      first_name: userFullName?.shift() || '',
      last_name: userFullName?.join(' ') || '',
    },
  };
};

/**
 * submit form of the second onboarding step
 *
 * @returns gateway response
 */
export const whoAreCreatingSigForFormSubmit = async (
  data: OnboardingData
): Promise<CompletedStepResponse> => {
  const res = await onboardingGateway.setAccountType(data.accountTypeInfo);
  return {
    statusCode: res.statusCode,
    eventName: 'onboarding_use_case_completed',
    eventAttributes: {
      use_case:
        data.accountTypeInfo.accountType == AccountType.TEAMS
          ? 'company'
          : 'solo',
      industry: data.accountTypeInfo.companyIndustry || '',
    },
    cioEventName: 'company_data_update',
    cioEventAttributes: {
      company_indutry: data.accountTypeInfo.companyIndustry || '',
    },
  };
};

/**
 * submit form of the third onboarding step
 *
 * @returns gateway response
 */
export const nameYourWorkspaceFormSubmit = async (
  data: OnboardingData
): Promise<CompletedStepResponse> => {
  const res = await onboardingGateway.setWorkspace(data.workspaceInfo);
  return {
    statusCode: res.statusCode,
    eventName: 'onboarding_email_provider_completed',
    eventAttributes: {
      demo_scheduled: `${data.demoScheduled}`,
    },
  };
};

/**
 * define if overlay should be visible or hidden
 *
 * @returns true/false
 */
export const showOverlay = (
  data: OnboardingData,
  stepName: screenName
): boolean => {
  if (
    stepName == screenName.ONBOARD_SCREEN_WHO_ARE_CREATING_SIG_FOR &&
    data.accountTypeInfo.accountType == AccountType.SOLO
  ) {
    return true;
  }
  if (stepName == screenName.ONBOARD_SCREEN_NAME_YOUR_WORKSPACE) {
    return true;
  }
  return false;
};

/**
 * build link to calendly with relevant user data
 *
 * @returns string
 */
export const setCalendlyUrl = (data: OnboardingData): void => {
  const userCountryData = Object.values(countries)
    .filter((d) => d.code.toLowerCase() === data.userCountry.toLowerCase())
    .pop();

  if (!userCountryData || userCountryData.region == 4) {
    data.calendlyUrl = '';
    return;
  }

  const JsonCalendlyForms = JSON.parse(JSON.stringify(calendlyForms));
  const formId =
    JsonCalendlyForms[userCountryData.region][
      data.userBasicInfo.companySize || 'medium'
    ];

  const calendlyUrl = encodeURI(
    `https://calendly.com/d/${formId}?embed_domain=*&embed_type=Inline&country_code=${data.userCountry}&hide_event_type_details=1&hide_gdpr_banner=1&text_color=08415c&primary_color=f5467e&utm_content=ws1_onboarding&name=${data.userBasicInfo.userName || ''}&email=${data.userEmail || ''}&a1=${userCountryData.phoneCode}${data.userBasicInfo.userMobile || ''}&a2=${data.userBasicInfo.companyName || ''}`
  );

  data.calendlyUrl = calendlyUrl;
  return;
};

/**
 * send analytics
 *
 */
export const sendAnalytics = async (
  resp: CompletedStepResponse
): Promise<void> => {
  if (
    resp.statusCode !== undefined &&
    resp.statusCode !== ResponseStatusCode.NO_ERRORS
  )
    return;

  if (resp.eventName) {
    const trackEventData = {
      eventName: 'session',
      eventMetadata: {
        category: 'onboarding',
        action: resp.eventName || '',
        attributes: {
          ...{ account_id: currentUser.accountId },
          ...(resp.eventAttributes ? resp.eventAttributes : {}),
        },
      },
    };

    // await trackEvent(trackEventData);
  }

  if (resp.cioEventName) {
    // await cioTrack({
    //   name: resp.cioEventName,
    //   attributes: resp.cioEventAttributes,
    // });
  }
};
