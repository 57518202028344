<script setup lang="ts">
import { ref } from 'vue';
import onboardingStrings from '../../onboarding.strings';
import radioButton from '../radio-button.vue';
import { AccountType } from '@msl/onboarding-gateway-sdk';
import { OnboardingData, RadioButtonOption } from '../../types';

const onboardingData = defineModel<OnboardingData>({
  required: true,
});
const accountTypeOptions = ref<RadioButtonOption[]>([
  { value: AccountType.SOLO, label: onboardingStrings.accountTypeOptionJustMe },
  {
    value: AccountType.TEAMS,
    label: onboardingStrings.accountTypeOptionCompany,
  },
]);
const companyIndustryOptions = ref<RadioButtonOption[]>([
  {
    value: 'TECHNOLOGY',
    label: onboardingStrings.companyIndustryOptionTechnology,
  },
  {
    value: 'REAL_ESTATE',
    label: onboardingStrings.companyIndustryOptionRealEstate,
  },
  {
    value: 'HEALTH_CARE',
    label: onboardingStrings.companyIndustryOptionHealthcare,
  },
  {
    value: 'BUSINESS_SERVICES',
    label: onboardingStrings.companyIndustryOptionBusiness,
  },
  { value: 'FINANCE', label: onboardingStrings.companyIndustryOptionFinance },
  {
    value: 'NON_PROFIT',
    label: onboardingStrings.companyIndustryOptionNonProfit,
  },
  {
    value: 'MARKETING',
    label: onboardingStrings.companyIndustryOptionMarketing,
  },
  {
    value: 'EDUCATION',
    label: onboardingStrings.companyIndustryOptionEducation,
  },
  { value: 'LEGAL', label: onboardingStrings.companyIndustryOptionLegal },
  {
    value: 'ARCHITECTURE_CONSTRUCTION',
    label: onboardingStrings.companyIndustryOptionArchitecture,
  },
  { value: 'RETAIL', label: onboardingStrings.companyIndustryOptionRetail },
  {
    value: 'INDUSTRIAL',
    label: onboardingStrings.companyIndustryOptionIndustrial,
  },
  {
    value: 'HOSPITALITY',
    label: onboardingStrings.companyIndustryOptionHospitality,
  },
  {
    value: 'MEDIA_ENTERTAINMENT',
    label: onboardingStrings.companyIndustryOptionMedia,
  },
  {
    value: 'TRANSPORTATION',
    label: onboardingStrings.companyIndustryOptionTransportation,
  },
  {
    value: 'PUBLIC_GOVERNMENT',
    label: onboardingStrings.companyIndustryOptionPublic,
  },
  { value: 'OTHER', label: onboardingStrings.companyIndustryOptionOther },
]);
</script>
<template>
  <div class="text-3xl font-semibold form-header">
    {{ onboardingStrings.whoAreCreatingSigForFormHeader }}
  </div>
  <radio-button
    v-model="onboardingData.accountTypeInfo.accountType"
    :options="accountTypeOptions"
    option-width="243px"
    aid="ONBOARDING_ACCOUNT_TYPE"
  ></radio-button>
  <div
    v-if="
      onboardingData.accountTypeInfo.accountType !== undefined &&
      onboardingData.accountTypeInfo.accountType in AccountType
    "
    class="industry-options"
  >
    <div class="text-3xl font-semibold form-header">
      {{ onboardingStrings.whoAreCreatingSigForFormIndustryHeader }}
    </div>
    <radio-button
      v-model="onboardingData.accountTypeInfo.companyIndustry"
      :options="companyIndustryOptions"
      aid="ONBOARDING_INDUSTRY"
    ></radio-button>
  </div>
</template>
<style lang="css" scoped src="../../assets/css/onboarding-forms.css"></style>
<style lang="css" scoped>
.industry-options {
  max-width: 700px;
  position: absolute;
}
</style>
