export default {
  generalServerError: 'Something went wrong. Please try again.',
  demoSessionMarginHeader: 'Trusted by',
  buttonContinueLabel: 'Continue',
  buttonContinueDisabledTooltip: 'Please fill all required data',
  buttonBackLabel: 'Back',
  buttonSkipLabel: 'Skip',
  tellUsAboutYourFormHeader: 'Tell us a bit about you',
  userNameLabel: 'Name',
  userNameRequired: 'Please fill in your name',
  userTitleLabel: 'Title',
  userMobileLabel: 'Mobile',
  userMobileRequired: 'Please fill in your mobile',
  companyNameRequired: 'Please fill in your company name',
  userMobileInvalid: 'mobile invalid',
  companyNameLabel: 'Company name',
  tellUsAboutYourFormCompanySizeHeader: 'How big is the company you work at?',
  whoAreCreatingSigForFormHeader: 'Who are you creating signature for?',
  whoAreCreatingSigForFormIndustryHeader: 'Which best describes your industry?',
  nameYourWorkspaceFormEmailPlatformHeader: 'What is your email platform?',
  accountTypeOptionJustMe: 'Myself',
  accountTypeOptionCompany: 'My team',
  companyIndustryOptionTechnology: 'Technology',
  companyIndustryOptionRealEstate: 'Real Estate',
  companyIndustryOptionHealthcare: 'Healthcare',
  companyIndustryOptionBusiness: 'Business Services',
  companyIndustryOptionFinance: 'Finance',
  companyIndustryOptionNonProfit: 'Non-Profit',
  companyIndustryOptionMarketing: 'Marketing',
  companyIndustryOptionEducation: 'Education',
  companyIndustryOptionLegal: 'Legal',
  companyIndustryOptionArchitecture: 'Architecture & Construction',
  companyIndustryOptionRetail: 'Retail',
  companyIndustryOptionIndustrial: 'Industrial',
  companyIndustryOptionHospitality: 'Hospitality',
  companyIndustryOptionMedia: 'Media & Entertainment',
  companyIndustryOptionTransportation: 'Transportation',
  companyIndustryOptionPublic: 'Public & Government',
  companyIndustryOptionOther: 'Other',
  bookDemoSessionOptionYes: 'Yes',
  bookDemoSessionOptionNo: 'No',
  companySizeOptionSmall: '1-10',
  companySizeOptionMedium: '11-50',
  companySizeOptionLarge: '51-200',
  companySizeOptionXlarge: '201+',
  emailPlatformOptionMicrosoft: 'Microsoft',
  emailPlatformOptionGoogle: 'Google Workspace',
  emailPlatformOptionOther: 'Other',
  demoSessionFormHeader: 'Would you like to book a free demo session?',
  completeOnboardingGettingReadyMsg: 'Getting ready',
  completeOnboardingTakingToWorkspace:
    'All set! Taking you to your account now...',
  demoSessionDialogHeader: 'Book your free demo session',
};
