<script setup lang="ts">
import { ref } from 'vue';
import { WsInput } from '@mfl/common-components';
import onboardingStrings from '../../onboarding.strings';
import radioButton from '../radio-button.vue';
import { OnboardingData, RadioButtonOption } from '../../types';
import { isEmptyString } from '../../utils';

const onboardingData = defineModel<OnboardingData>({
  required: true,
});

const companySizeOptions = ref<RadioButtonOption[]>([
  { value: 'small', label: onboardingStrings.companySizeOptionSmall },
  { value: 'medium', label: onboardingStrings.companySizeOptionMedium },
  { value: 'large', label: onboardingStrings.companySizeOptionLarge },
  { value: 'xlarge', label: onboardingStrings.companySizeOptionXlarge },
]);

function userNameValidation(val: string) {
  if (isEmptyString(val)) return onboardingStrings.userNameRequired;
  return true;
}

function userMobileValidation(val: string) {
  if (isEmptyString(val)) return onboardingStrings.userMobileRequired;
  return true;
}

function companyNameValidation(val: string) {
  if (isEmptyString(val)) return onboardingStrings.companyNameRequired;
  return true;
}
</script>
<template>
  <div class="text-3xl font-semibold form-header">
    {{ onboardingStrings.tellUsAboutYourFormHeader }}
  </div>
  <WsInput
    v-model.trim="onboardingData.userBasicInfo.userName"
    aid="ONBOARDING_USER_NAME"
    debounce="500"
    size="lg"
    :label="onboardingStrings.userNameLabel"
    :rules="[userNameValidation]"
  ></WsInput>
  <WsInput
    v-model.trim="onboardingData.userBasicInfo.userTitle"
    aid="ONBOARDING_USER_TITLE"
    debounce="500"
    size="lg"
    :label="onboardingStrings.userTitleLabel"
  ></WsInput>
  <WsInput
    v-model.trim="onboardingData.userBasicInfo.userMobile"
    aid="ONBOARDING_USER_MOBILE"
    debounce="500"
    size="lg"
    :label="onboardingStrings.userMobileLabel"
    :rules="[userMobileValidation]"
  ></WsInput>
  <WsInput
    v-model.trim="onboardingData.userBasicInfo.companyName"
    aid="ONBOARDING_USER_COMPANY_NAME"
    debounce="500"
    size="lg"
    :label="onboardingStrings.companyNameLabel"
    :rules="[companyNameValidation]"
  ></WsInput>
  <div class="text-3xl font-semibold form-header">
    {{ onboardingStrings.tellUsAboutYourFormCompanySizeHeader }}
  </div>
  <radio-button
    v-model="onboardingData.userBasicInfo.companySize"
    :options="companySizeOptions"
    option-width="115px"
    aid="ONBOARDING_USER_COMPANY_SIZE"
  ></radio-button>
</template>
<style lang="css" scoped src="../../assets/css/onboarding-forms.css"></style>
