<script setup lang="ts">
import onboardingStrings from '../../onboarding.strings';
import radioButton from '../radio-button.vue';
import { OnboardingData, RadioButtonOption } from '../../types';
import { ref } from 'vue';
import { EmailPlatform } from '@msl/onboarding-gateway-sdk';
import { openInviteEmailPreviewDialog } from '../calendly/calendly-dialog';
import { bookDemoSession } from '../../consts';

const onboardingData = defineModel<OnboardingData>({
  required: true,
});

const emailPlatformOptions = ref<RadioButtonOption[]>([
  {
    value: EmailPlatform.MICROSOFT,
    label: onboardingStrings.emailPlatformOptionMicrosoft,
    className: 'microsoft',
  },
  {
    value: EmailPlatform.GOOGLE,
    label: onboardingStrings.emailPlatformOptionGoogle,
    className: 'google',
  },
  {
    value: EmailPlatform.OTHER,
    label: onboardingStrings.emailPlatformOptionOther,
  },
]);

const bookDemoSessionOptions = ref<RadioButtonOption[]>([
  {
    value: bookDemoSession.YES,
    label: onboardingStrings.bookDemoSessionOptionYes,
  },
  {
    value: bookDemoSession.NO,
    label: onboardingStrings.bookDemoSessionOptionNo,
  },
]);

const handleRadionBtnClick = async () => {
  if (
    !onboardingData.value.demoScheduled &&
    onboardingData.value.demoSession == 1
  ) {
    const res = await openInviteEmailPreviewDialog({
      calendlyUrl: onboardingData.value.calendlyUrl,
    });
    onboardingData.value.demoScheduled = res?.demoScheduled || false;
  }
};
</script>
<template>
  <div class="text-3xl font-semibold form-header">
    {{ onboardingStrings.nameYourWorkspaceFormEmailPlatformHeader }}
  </div>
  <radio-button
    v-model="onboardingData.workspaceInfo.emailPlatform"
    :options="emailPlatformOptions"
    aid="ONBOARDING_EMAIL_PLATFORM"
  ></radio-button>
  <template v-if="onboardingData.calendlyUrl">
    <div class="text-3xl font-semibold form-header">
      {{ onboardingStrings.demoSessionFormHeader }}
    </div>
    <radio-button
      v-model="onboardingData.demoSession"
      :options="bookDemoSessionOptions"
      :disabled="onboardingData.demoScheduled"
      option-width="262px"
      aid="ONBOARDING_BOOK_DEMO_SESSION"
      @click="handleRadionBtnClick"
    ></radio-button>
  </template>
</template>
<style lang="css" scoped src="../../assets/css/onboarding-forms.css"></style>
<style>
.microsoft {
  background-image: url('../../assets/imgs/microsoft.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  padding-left: 30px;
}

.google {
  background-image: url('../../assets/imgs/google.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  padding-left: 30px;
}
</style>
