import tellUsAboutYouMargin from './components/margins/tell-us-about-you.vue';
import whoAreCreatingSigForMargin from './components/margins/who-are-creating-sig-for.vue';
import nameYourWorkspaceMargin from './components/margins/name-your-workspace.vue';
import tellUsAboutYouForm from './components/forms/tell-us-about-you.vue';
import whoAreCreatingSigForForm from './components/forms/who-are-creating-sig-for.vue';
import nameYourWorkspaceForm from './components/forms/name-your-workspace.vue';
import {
  nameYourWorkspaceFormSubmit,
  tellUsAboutYouFormSubmit,
  whoAreCreatingSigForFormSubmit,
} from './form-actions';

export enum screenName {
  ONBOARD_SCREEN_TELL_US_ABOUT_YOU = 'ONBOARD_SCREEN_TELL_US_ABOUT_YOU',
  ONBOARD_SCREEN_WHO_ARE_CREATING_SIG_FOR = 'ONBOARD_SCREEN_WHO_ARE_CREATING_SIG_FOR',
  ONBOARD_SCREEN_NAME_YOUR_WORKSPACE = 'ONBOARD_SCREEN_NAME_YOUR_WORKSPACE',
}

export enum bookDemoSession {
  NO = 0,
  YES = 1,
}

export const screens = [
  {
    aid: screenName.ONBOARD_SCREEN_TELL_US_ABOUT_YOU,
    svg: tellUsAboutYouMargin,
    form: tellUsAboutYouForm,
    action: tellUsAboutYouFormSubmit,
  },
  {
    aid: screenName.ONBOARD_SCREEN_WHO_ARE_CREATING_SIG_FOR,
    svg: whoAreCreatingSigForMargin,
    form: whoAreCreatingSigForForm,
    action: whoAreCreatingSigForFormSubmit,
  },
  {
    aid: screenName.ONBOARD_SCREEN_NAME_YOUR_WORKSPACE,
    svg: nameYourWorkspaceMargin,
    form: nameYourWorkspaceForm,
    action: nameYourWorkspaceFormSubmit,
  },
];
